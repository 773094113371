import React from 'react';
import "./products.css";


 import paas from "../../assets/images/products/paas-img.svg"
import sbp from "../../assets/images/products/sbp-img.svg"


const Products = () => {
  return (
   <section id='products'>
    
     <div className='container main-products'>
           <div className='row products-content'>
                 <div className='col-lg-6 col-md-6 col-sm-12 products12'>
                    <h2 className='products-main-title'>Products</h2>
                </div>
            </div>
          
           <div className='row products-text-main products-bottom-line products-column-reverse'>  
          
           <div className='col-lg-5 col-md-5 col-sm-12 products-col'>
             <h4 className='products-subtitle'>Proving as a Service</h4>
                     

                       <p>We provide 'Proving-as-a-Service' solutions as a foundational infrastructure for an efficient ZK Proving Ecosystem.</p>

                       

           </div>
            <div className='col-lg-4 col-md-4 col-sm-12 products-col-img'>
               <img className='products-img-paas' src={paas} alt="paas" />
           </div>
             
             
              
            </div>
            
            
             <div className='row products-text-main'> 

                 <div className='col-lg-4 col-md-4 col-sm-12 products-col-img'>
               <img className='products-img-sbp' src={sbp} alt="sbp" /> 
                    
                    
            
             </div> 
             
             <div className='col-lg-5 col-md-5 col-sm-12 products-col'>
                   <h4 className='products-subtitle'>STARK-Based Provers</h4>
              <p>Ponos technology designes, develops, and deploys end-to-end HW-SW co-design optimized STARK-based provers.</p> 
             </div>
              
             

            </div>
            
        </div>
   </section>
  )
}

export default Products

import React from 'react';

import './logo2.css';

import logo from "../../assets/images/logo2/ponos-logo-mark-blue-original.svg";


//  import {Link} from 'react-router-dom';

import { Link as LinkScroll } from 'react-scroll';

const Logo2 = () => {




  return (

    <LinkScroll to='header' className="navbar-brand2">

      <span className="nav-logo2-content">
       
          <img className="nav-logo2-img" src={logo} alt="ponos-logo-mark" />
          

      </span>


    </LinkScroll>




  )
}

export default Logo2
